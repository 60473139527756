/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm, Fullmap, FullmapWrap, FullmapCover, SeparateLine, SeparateLineWrap, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60" style={{"paddingTop":393}} name={"dyhwkhkynnn"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box lh--1" style={{"paddingTop":0,"marginBottom":0}} content={"Chuť <span style=\"font-style: italic;\">vítězství</span> <br>ve vaší sklenici"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"Objevte jedinečné kolekce vín vytvořených<br>ve spolupráci s legendami sportu.&nbsp;"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":536}} content={"Máte dotaz ohledně naší nabídky, vín nebo objednávky? Neváhejte nás kontaktovat – rádi vám poradíme s výběrem či odpovíme na jakékoliv otázky.<br><br>Pokud si chcete objednat konkrétní vína, napište nám jednotlivé položky a jejich počty do textu zprávy. Nezapomeňte uvést i místo, kde budete chtít objednávku doručit.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" style={{"paddingTop":0}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"5ra30vxzzder"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"2qwmdwsvqcsb"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"84hheqz7t0yi","required":true},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zde napište dotaz, nebo položky objednávky.","id":"nwl33kundxmo"},{"name":"Odeslat","type":"submit","id":"y555i53il5q6"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--40 pt--80" name={"hy45dz5hatj"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Seznam míst <span style=\"font-style: italic;\">k vyzvednutí</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"au4ljd0an8n"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="mt--0" style={{"paddingBottom":0}}>
              
              <Title className="title-box fs--43" content={"Čeladná"}>
              </Title>

              <Text className="text-box" content={"<a href=\"https://www.uholubu.com/\">Hotel &amp; Garden U Holubů<br></a><a href=\"https://www.facebook.com/kovarnaceladna/?_rdr\" target=\"_blank\">Kovárna Čeladná</a><br><a href=\"https://amiciespressobar.makro.bar/?lang=cs\" target=\"_blank\">AMICI espresso bar</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"Praha"}>
              </Title>

              <Text className="text-box" content={"<a href=\"https://cortelazzi.cz/cs/\" target=\"_blank\">Hotel Maxmilian<br>Cortelazzi Praha</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"Bratislava"}>
              </Title>

              <Text className="text-box" content={"<a href=\"https://cortelazzi.sk/sk/\" target=\"_blank\">Cortelazzi Bratislava</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"Online nákup"}>
              </Title>

              <Text className="text-box" content={"<a href=\"https://cortelazzi.sk/sk/\" target=\"_blank\">Fanshop Baník</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" style={{"backgroundColor":"var(--color-supplementary)"}} name={"c7k8i2zmc8w"}>
          
          <FullmapWrap className="pb--20 pt--20">
          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover place={"praha"} zoom={"11"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pt--60" style={{"paddingBottom":0}} name={"sym1uznisrd"}>
        </Column>


        <Column className="--center" name={"wk7aqc5gwg9"}>
        </Column>


        <SeparateLine className="pb--10 pt--10" name={"iwvdzgwz4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 797 811 2X0<br>info@vinalegend.com</span>"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/jankul18/"} url={"https://www.instagram.com/jankul18/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}